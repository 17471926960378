import "./Projects.css";
import { useTranslation } from 'react-i18next';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Carousel from 'react-bootstrap/Carousel';
import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';

/* Math Fractal Proj */
import mathfractalproj_slide_one from '../../imgs/mathfractalproj/mathfractalproj_first_slide.png';
import mathfractalproj_slide_two from '../../imgs/mathfractalproj/mathfractalproj_second_slide.png';
import mathfractalproj_slide_three from '../../imgs/mathfractalproj/mathfractalproj_third_slide.png';

/* Math Pendulum Proj */
import mathpendulumproj_slide_one from '../../imgs/mathpendulumproj/mathpendulumproj_first_slide.png';
import mathpendulumproj_slide_two from '../../imgs/mathpendulumproj/mathpendulumproj_second_slide.png';
import mathpendulumproj_slide_three from '../../imgs/mathpendulumproj/mathpendulumproj_third_slide.png';

/* Math Pendulum Proj */
import mathfluidproj_slide_one from '../../imgs/mathfluidproj/mathfluidproj_first_slide.png';
import mathfluidproj_slide_two from '../../imgs/mathfluidproj/mathfluidproj_second_slide.png';
import mathfluidproj_slide_three from '../../imgs/mathfluidproj/mathfluidproj_third_slide.png';
import mathfluidproj_slide_four from '../../imgs/mathfluidproj/mathfluidproj_fourth_slide.png';
import mathfluidproj_slide_five from '../../imgs/mathfluidproj/mathfluidproj_fifth_slide.png';

/* Miscellaneous Penguin Proj */
import miscellaneouspenguinproj_slide_one from '../../imgs/miscellaneouspenguinproj/miscellaneouspenguinproj_first_slide.png';
import miscellaneouspenguinproj_slide_two from '../../imgs/miscellaneouspenguinproj/miscellaneouspenguinproj_second_slide.png';

/* Miscellaneous Engine Proj */
import miscellaneousengineproj_slide_one from '../../imgs/miscellaneousengineproj/miscellaneousengineproj_first_slide.png';
import miscellaneousengineproj_slide_two from '../../imgs/miscellaneousengineproj/miscellaneousengineproj_second_slide.png';

/* Software Marketplace Proj */
import miscellaneousmarketplaceproj_slide_one from '../../imgs/softwaremarketplaceproj/softwaremarketplaceproj_first_slide.png';
import miscellaneousmarketplaceproj_slide_two from '../../imgs/softwaremarketplaceproj/softwaremarketplaceproj_second_slide.png';

import kitten_1 from '../../imgs/kitten_1.png';
import kitten_2 from '../../imgs/kitten_2.png';
import kitten_3 from '../../imgs/kitten_3.png';

function downloadFile(url, filename) {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

/**
 * downloadFileByLang - Made to download files when there is a matter of language
 * @param {const} filePerLangArray - Array of array of format [[importedURL, fileName, 'us'], [importedURL, fileName, 'fr'], ...].
 * @param {const} langState - State (t) of current lang set.
 */
function downloadFileByLang(filePerLangArray, langState) {
    let fileData = [];

    filePerLangArray.forEach(subArray => {
        if (subArray[2] === langState('current_lang')){
            fileData = [subArray[0], subArray[1]];
        }
    });

    const link = document.createElement('a');
    link.href = fileData[0];
    link.setAttribute('download', fileData[1]);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

const Projects = () => {
    const [showFractalDownloads, setShowFractalDownloads] = useState(false);
    const [showPendulumDownloads, setShowPendulumDownloads] = useState(false);
    const [showFluidDownloads, setShowFluidDownloads] = useState(false);
    const [showPenguinDownloads, setShowPenguinDownloads] = useState(false);
    const [showEngineDownloads, setShowEngineDownloads] = useState(false);
    
    const handleShowsetShowFractalDownloads = () => { setShowFractalDownloads(true) };
    const handleClosesetShowFractalDownloads = () => { setShowFractalDownloads(false) };
    const handleShowsetShowPendulumDownloads = () => { setShowPendulumDownloads(true); };
    const handleClosesetShowPendulumDownloads = () => { setShowPendulumDownloads(false); };
    const handleShowsetShowFluidDownloads = () => { setShowFluidDownloads(true); };
    const handleClosesetShowFluidDownloads = () => { setShowFluidDownloads(false); };
    const handleShowsetShowPenguinDownloads = () => { setShowPenguinDownloads(true); };
    const handleClosesetShowPenguinDownloads = () => { setShowPenguinDownloads(false); };
    const handleShowsetShowEngineDownloads = () => { setShowEngineDownloads(true); };
    const handleClosesetShowEngineDownloads = () => { setShowEngineDownloads(false); };

    const { t } = useTranslation();

    return (
        <div className="flex flex-col md:flex-row lg:max-w-7xl lg:mt-[200px] mt-[80px] mx-auto items-center text-justify w-full"  style={{ zIndex: -1 }}>
            <div className="text-sm lg:ml-7 p-4 w-full mb-0">
                <h1 className="pb-4 text-lg lg:text-3xl">{t('projects')}</h1>
                <Tabs defaultActiveKey="software" id="uncontrolled-tab-example" className="mb-3">
                    <Tab eventKey="software" title="Software">
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">
                            <div>
                                <Carousel interval={null}>
                                    <Carousel.Item>
                                        <img className="d-block w-100" src={miscellaneousmarketplaceproj_slide_one} alt="slide_1_img"/>
                                        <div className="projClickForModal" onClick={handleShowsetShowEngineDownloads}></div>
                                        <Carousel.Caption>
                                            <div className="projDarkBackground">
                                                <h3 className="text-white">0-CONNEXION MARKETPLACE</h3>
                                                <p className="text-white">A simple Marketplace made for fast and easy consumption.</p>
                                            </div>
                                        </Carousel.Caption>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <img className="d-block w-100" src={miscellaneousmarketplaceproj_slide_two} alt="slide_2_img"/>
                                        <div className="projClickForModal" onClick={handleShowsetShowEngineDownloads}></div>
                                        <Carousel.Caption>
                                            <div className="projDarkBackground">
                                                <h3 className="text-white">0-CONNEXION MARKETPLACE</h3>
                                                <p className="text-white">Made in a powerful and responsive Angular.JS framework.</p>
                                            </div>
                                        </Carousel.Caption>
                                    </Carousel.Item>
                                </Carousel>
                            </div>
                            <Modal show={showEngineDownloads} centered onHide={handleClosesetShowEngineDownloads} animation={true}>
                                <Modal.Header closeButton className="pr-4">
                                    <Modal.Title>Documents 3D Engine</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="p-2 documentpointer" onClick={() => downloadFileByLang('temp', 'temp')}>Documentation</div>
                                    <div className="p-2 documentpointer" onClick={() => downloadFileByLang('temp', 'temp')}>Project Folder</div>
                                </Modal.Body>
                            </Modal>
                        </div>
                    </Tab>
                    <Tab eventKey="miscellaneous" title="Miscellaneous">
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">
                            <div>
                                <Carousel interval={null}>
                                    <Carousel.Item>
                                        <img className="d-block w-100" src={miscellaneouspenguinproj_slide_one} alt="slide_1_img"/>
                                        <div className="projClickForModal" onClick={handleShowsetShowPenguinDownloads}></div>
                                        <Carousel.Caption>
                                            <div className="projDarkBackground">
                                                <h3 className="text-white">HUNTING LEARNING PENGUIN</h3>
                                                <p className="text-white">A discorvering project on how to train some Penguins NPC to hunt fishes NPC on a 3D environment.</p>
                                            </div>
                                        </Carousel.Caption>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <img className="d-block w-100" src={miscellaneouspenguinproj_slide_two} alt="slide_2_img"/>
                                        <div className="projClickForModal" onClick={handleShowsetShowPenguinDownloads}></div>
                                        <Carousel.Caption>
                                            <div className="projDarkBackground">
                                                <h3 className="text-white">HUNTING LEARNING PENGUIN</h3>
                                                <p className="text-white">Made with Unity, in a 3D model project with clean C# scripts.</p>
                                            </div>
                                        </Carousel.Caption>
                                    </Carousel.Item>
                                </Carousel>
                            </div>
                            <Modal show={showPenguinDownloads} centered onHide={handleClosesetShowPenguinDownloads} animation={true}>
                                <Modal.Header closeButton className="pr-4">
                                    <Modal.Title>Documents Hunting Learning Penguins</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="p-2 documentpointer" onClick={() => downloadFileByLang('temp', 'temp')}>Documentation</div>
                                    <div className="p-2 documentpointer" onClick={() => downloadFileByLang('temp', 'temp')}>Project Folder</div>
                                </Modal.Body>
                            </Modal>
                            
                            <div>
                                <Carousel interval={null}>
                                    <Carousel.Item>
                                        <img className="d-block w-100" src={miscellaneousengineproj_slide_one} alt="slide_1_img"/>
                                        <div className="projClickForModal" onClick={handleShowsetShowEngineDownloads}></div>
                                        <Carousel.Caption>
                                            <div className="projDarkBackground">
                                                <h3 className="text-white">3D ENGINE</h3>
                                                <p className="text-white">A discorvering project on how to train some Penguins NPC to hunt fishes NPC on a 3D environment.</p>
                                            </div>
                                        </Carousel.Caption>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <img className="d-block w-100" src={miscellaneousengineproj_slide_two} alt="slide_2_img"/>
                                        <div className="projClickForModal" onClick={handleShowsetShowEngineDownloads}></div>
                                        <Carousel.Caption>
                                            <div className="projDarkBackground">
                                                <h3 className="text-white">3D ENGINE</h3>
                                                <p className="text-white">Made with Unity, in a 3D model project with clean C# scripts.</p>
                                            </div>
                                        </Carousel.Caption>
                                    </Carousel.Item>
                                </Carousel>
                            </div>
                            <Modal show={showEngineDownloads} centered onHide={handleClosesetShowEngineDownloads} animation={true}>
                                <Modal.Header closeButton className="pr-4">
                                    <Modal.Title>Documents 3D Engine</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="p-2 documentpointer" onClick={() => downloadFileByLang('temp', 'temp')}>Documentation</div>
                                    <div className="p-2 documentpointer" onClick={() => downloadFileByLang('temp', 'temp')}>Project Folder</div>
                                </Modal.Body>
                            </Modal>
                        </div>
                    </Tab>
                    <Tab eventKey="mathematics" title="Mathematics">
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">
                            <div>
                                <Carousel interval={null}>
                                    <Carousel.Item>
                                        <img className="d-block w-100" src={mathfractalproj_slide_one} alt="slide_1_img"/>
                                        <div className="projClickForModal" onClick={handleShowsetShowFractalDownloads}></div>
                                        <Carousel.Caption>
                                            <div className="projDarkBackground">
                                                <h3 className="text-white">RECURSIVE FRACTALS</h3>
                                                <p className="text-white">A mathematic reflectional project on the utility of Fractals in informatic by developing a Recursive Fractal code.</p>
                                            </div>
                                        </Carousel.Caption>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <img className="d-block w-100" src={mathfractalproj_slide_two} alt="slide_2_img"/>
                                        <div className="projClickForModal" onClick={handleShowsetShowFractalDownloads}></div>
                                        <Carousel.Caption>
                                            <div className="projDarkBackground">
                                                <h3 className="text-white">RECURSIVE FRACTALS</h3>
                                                <p className="text-white">Simple and clean Python code project.</p>
                                            </div>
                                        </Carousel.Caption>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <img className="d-block w-100" src={mathfractalproj_slide_three} alt="slide_2_img"/>
                                        <div className="projClickForModal" onClick={handleShowsetShowFractalDownloads}></div>
                                        <Carousel.Caption>
                                            <div className="projDarkBackground">
                                                <h3 className="text-white">RECURSIVE FRACTALS</h3>
                                                <p className="text-white">Screenshot of the project once the main file is run in debug mode.</p>
                                            </div>
                                        </Carousel.Caption>
                                    </Carousel.Item>
                                </Carousel>
                            </div>
                            <Modal show={showFractalDownloads} centered onHide={handleClosesetShowFractalDownloads} animation={true}>
                                <Modal.Header closeButton className="pr-4">
                                    <Modal.Title>Documents Recursive Fractals</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="p-2 documentpointer" onClick={() => downloadFileByLang('temp', 'temp')}>Documentation</div>
                                    <div className="p-2 documentpointer" onClick={() => downloadFileByLang('temp', 'temp')}>Project Folder</div>
                                </Modal.Body>
                            </Modal>
                            
                            <div>
                                <Carousel interval={null}>
                                    <Carousel.Item>
                                        <img className="d-block w-100" src={mathpendulumproj_slide_one} alt="slide_1_img"/>
                                        <div className="projClickForModal" onClick={handleShowsetShowPendulumDownloads}></div>
                                        <Carousel.Caption>
                                            <div className="projDarkBackground">
                                                <h3 className="text-white">DOUBLE PENDULUM</h3>
                                                <p className="text-white">A mathematic technical project on the making of a Double Pendulum gif.</p>
                                            </div>
                                        </Carousel.Caption>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <img className="d-block w-100" src={mathpendulumproj_slide_two} alt="slide_2_img"/>
                                        <div className="projClickForModal" onClick={handleShowsetShowPendulumDownloads}></div>
                                        <Carousel.Caption>
                                            <div className="projDarkBackground">
                                                <h3 className="text-white">DOUBLE PENDULUM</h3>
                                                <p className="text-white">Made in Python with important libraries such as Matplotlib or Sympy.</p>
                                            </div>
                                        </Carousel.Caption>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <img className="d-block w-100" src={mathpendulumproj_slide_three} alt="slide_2_img"/>
                                        <div className="projClickForModal" onClick={handleShowsetShowPendulumDownloads}></div>
                                        <Carousel.Caption>
                                            <div className="projDarkBackground">
                                                <h3 className="text-white">DOUBLE PENDULUM</h3>
                                                <p className="text-white">Screenshot of the project once the GIF file generated.</p>
                                            </div>
                                        </Carousel.Caption>
                                    </Carousel.Item>
                                </Carousel>
                            </div>
                            <Modal show={showPendulumDownloads} centered onHide={handleClosesetShowPendulumDownloads} animation={true}>
                                <Modal.Header closeButton className="pr-4">
                                    <Modal.Title>Documents Pendulum</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="p-2 documentpointer" onClick={() => downloadFileByLang('temp', 'temp')}>Documentation</div>
                                    <div className="p-2 documentpointer" onClick={() => downloadFileByLang('temp', 'temp')}>Project Folder</div>
                                </Modal.Body>
                            </Modal>
                            
                            <div>
                                <Carousel interval={null}>
                                    <Carousel.Item>
                                        <img className="d-block w-100" src={mathfluidproj_slide_one} alt="slide_1_img"/>
                                        <div className="projClickForModal" onClick={handleShowsetShowFluidDownloads}></div>
                                        <Carousel.Caption>
                                            <div className="projDarkBackground">
                                                <h3 className="text-white">EULERIAN FLUID SIMULATION</h3>
                                                <p className="text-white">A technical mathematic web project on Eulerian Fluid Simulation focused on physics application in infortmatic.</p>
                                            </div>
                                        </Carousel.Caption>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <img className="d-block w-100" src={mathfluidproj_slide_two} alt="slide_2_img"/>
                                        <div className="projClickForModal" onClick={handleShowsetShowFluidDownloads}></div>
                                        <Carousel.Caption>
                                            <div className="projDarkBackground">
                                                <h3 className="text-white">EULERIAN FLUID SIMULATION</h3>
                                                <p className="text-white">Made in challenging a web format with HTML, CSS and JS.</p>
                                            </div>
                                        </Carousel.Caption>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <img className="d-block w-100" src={mathfluidproj_slide_three} alt="slide_3_img"/>
                                        <div className="projClickForModal" onClick={handleShowsetShowFluidDownloads}></div>
                                        <Carousel.Caption>
                                            <div className="projDarkBackground">
                                                <h3 className="text-white">EULERIAN FLUID SIMULATION</h3>
                                                <p className="text-white">Screenshot of the default setup once debug started.</p>
                                            </div>
                                        </Carousel.Caption>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <img className="d-block w-100" src={mathfluidproj_slide_four} alt="slide_4_img"/>
                                        <div className="projClickForModal" onClick={handleShowsetShowFluidDownloads}></div>
                                        <Carousel.Caption>
                                            <div className="projDarkBackground">
                                                <h3 className="text-white">EULERIAN FLUID SIMULATION</h3>
                                                <p className="text-white">Screenshot of streamlines and velocity vectors activated.</p>
                                            </div>
                                        </Carousel.Caption>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <img className="d-block w-100" src={mathfluidproj_slide_five} alt="slide_5_img"/>
                                        <div className="projClickForModal" onClick={handleShowsetShowFluidDownloads}></div>
                                        <Carousel.Caption>
                                            <div className="projDarkBackground">
                                                <h3 className="text-white">EULERIAN FLUID SIMULATION</h3>
                                                <p className="text-white">Screenshot of the pressure mode.</p>
                                            </div>
                                        </Carousel.Caption>
                                    </Carousel.Item>
                                </Carousel>
                            </div>
                            <Modal show={showFluidDownloads} centered onHide={handleClosesetShowFluidDownloads} animation={true}>
                                <Modal.Header closeButton className="pr-4">
                                    <Modal.Title>Documents Eulerian Fluid Simulation</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="p-2 documentpointer" onClick={() => downloadFileByLang('temp', 'temp')}>Documentation</div>
                                    <div className="p-2 documentpointer" onClick={() => downloadFileByLang('temp', 'temp')}>Project Folder</div>
                                </Modal.Body>
                            </Modal>
                        </div>
                    </Tab>
                </Tabs>
            </div>
        </div>
    );
}

export default Projects;