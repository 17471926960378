import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import "bootstrap/dist/css/bootstrap.min.css";
import './languages_management/i18n';
import 'bootstrap-icons/font/bootstrap-icons.css';

function Root() {
  return (
      <App>
        
      </App>
  );
}

createRoot(document.getElementById('root')).render( // Use createRoot from "react-dom/client"
  <React.StrictMode>
    <Root />
  </React.StrictMode>
);
