import "./SchoolCurriculum.css";
import { useTranslation } from 'react-i18next';
import foch_image from "../../imgs/foch.png";
import st_marguerite_image from "../../imgs/st_marguerite.png";
import studying_abroad_image from "../../imgs/studying_abroad.png";
import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';

import bulletin_proof from '../../downloads/bts_sio_bulletin.pdf'
import report_card_proof from '../../downloads/bts_sio_report_card.pdf'
import examen_blanc_proof from '../../downloads/bts_sio_examen_blanc.pdf';
import mock_exam_proof from '../../downloads/bts_sio_mock_exam.pdf';
import scholarship_certificate_first_year_proof from '../../downloads/bts_sio_scolarship_certificate_first_year.pdf';
import certificat_scolarite_premiere_annee_proof from '../../downloads/bts_sio_certificat_scolarite_premiere_annee.pdf';
import scholarship_certificate_second_year_proof from '../../downloads/bts_sio_scolarship_certificate_second_year.pdf';
import certificat_scolarite_deuxieme_annee_proof from '../../downloads/bts_sio_certificat_scolarite_seconde_annee.pdf';
import sat_score_proof from '../../downloads/sat_score.pdf';

const bulletin_file_name = 'bts_sio_bulletin.pdf';
const report_card_file_name = 'bts_sio_report_card.pdf'
const examen_blanc_file_name = 'bts_sio_examen_blanc.pdf';
const mock_exam_file_name = 'bts_sio_mock_exam.pdf';
const scholarship_certificate_first_year_file_name = 'bts_sio_scolarship_certificate_first_year.pdf';
const certificat_scolarite_premiere_annee_file_name = 'bts_sio_certificat_scolarite_premiere_annee.pdf';
const scholarship_certificate_second_year_file_name = 'bts_sio_scolarship_certificate_second_year.pdf';
const certificat_scolarite_deuxieme_annee_file_name = 'bts_sio_certificat_scolarite_seconde_annee.pdf';
const sat_score_file_name = 'sat_score.pdf';

function downloadFile(url, filename) {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

/**
 * downloadFileByLang - Made to download files when there is a matter of language
 * @param {const} filePerLangArray - Array of array of format [[importedURL, fileName, 'us'], [importedURL, fileName, 'fr'], ...].
 * @param {const} langState - State (t) of current lang set.
 */
function downloadFileByLang(filePerLangArray, langState) {
    let fileData = [];

    filePerLangArray.forEach(subArray => {
        if (subArray[2] == langState('current_lang')){
            fileData = [subArray[0], subArray[1]];
        }
    });

    const link = document.createElement('a');
    link.href = fileData[0];
    link.setAttribute('download', fileData[1]);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

const SchoolCurriculum = () => {
    const [showBtsDownloads, setShowBtsDownloads] = useState(false);
    const [showWhatNextDownloads, setShowWhatNextDownloads] = useState(false);
    
    const handleShowsetShowBtsDownloads = () => { setShowBtsDownloads(true) };
    const handleClosesetShowBtsDownloads = () => { setShowBtsDownloads(false) };
    const handleShowsetShowWhatNextDownloads = () => { setShowWhatNextDownloads(true); };
    const handleClosesetShowWhatNextDownloads = () => { setShowWhatNextDownloads(false); };

    /**
     * Setting up download per lang variables
     */
    const filePerLangReportCard = [
        [report_card_proof, report_card_file_name, 'us'], 
        [bulletin_proof, bulletin_file_name, 'fr'], 
        [report_card_proof, report_card_file_name, 'en'], 
        [report_card_proof, report_card_file_name, 'ca']];
    const filePerLangMockExam = [
        [mock_exam_proof, mock_exam_file_name, 'us'], 
        [examen_blanc_proof, examen_blanc_file_name, 'fr'], 
        [mock_exam_proof, mock_exam_file_name, 'en'], 
        [mock_exam_proof, mock_exam_file_name, 'ca']];
    const filePerLangSCFY = [
        [scholarship_certificate_first_year_proof, scholarship_certificate_first_year_file_name, 'us'], 
        [certificat_scolarite_premiere_annee_proof, certificat_scolarite_premiere_annee_file_name, 'fr'], 
        [scholarship_certificate_first_year_proof, scholarship_certificate_first_year_file_name, 'en'], 
        [scholarship_certificate_first_year_proof, scholarship_certificate_first_year_file_name, 'ca']];
    const filePerLangSCSY = [
        [scholarship_certificate_second_year_proof, scholarship_certificate_second_year_file_name, 'us'], 
        [certificat_scolarite_deuxieme_annee_proof, certificat_scolarite_deuxieme_annee_file_name, 'fr'], 
        [scholarship_certificate_second_year_proof, scholarship_certificate_second_year_file_name, 'en'], 
        [scholarship_certificate_second_year_proof, scholarship_certificate_second_year_file_name, 'ca']];

    const { t } = useTranslation();

    return (  
        <div className="flex flex-col md:flex-row lg:max-w-7xl lg:mt-[200px] mt-[80px] mx-auto items-center text-justify w-full"  style={{ zIndex: -1 }}>
            <div className="text-sm lg:ml-7 p-4 w-full mb-0">
                <h1 className="pb-4 text-lg lg:text-3xl">{t('scholarship_curriculum')}</h1>
                <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-4">
                    <div className="flex-auto cdefault">
                        <article className="border rounded-lg overflow-hidden" style={{ height: "490px" }}>
                            <figure>
                                <img src={foch_image} alt="Placeholder" className="w-full h-48 object-cover" />
                            </figure>
                            <div className="p-4 sameHeigthDiv" style={{ height: '257px' }}>
                                <h2 className="text-base font-semibold mb-2">{t('high_school')}</h2>
                                <p className="text-xs text-gray-700">
                                    {t('highschool_desc')}
                                    <ul>
                                        <li>{t('spec_el')}</li>
                                        <li>{t('spec_m')}</li>
                                    </ul>
                                    {t('high_school_place')}
                                </p>
                            </div>
                            <a class="read-more" className="p-4"><span class="sr-only"></span>
                                <svg xmlns="http://www.w3.org/2000/svg" class="icon" viewBox="0 0 20 20" fill="currentColor">
                                    <path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd" />
                                </svg>
                            </a>
                        </article>
                    </div>

                    <div className="flex-auto cpointer" onClick={handleShowsetShowBtsDownloads}>
                        <article className="border rounded-lg overflow-hidden" style={{ height: "490px" }}>
                            <figure>
                                <img src={st_marguerite_image} alt="Placeholder" className="w-full h-48 object-cover" />
                            </figure>
                            <div className="p-4 sameHeigthDiv">
                                <h2 className="text-base font-semibold mb-2">{t('two_year_degree')}</h2>
                                <p className="text-xs text-gray-700">
                                    {t('two_year_college_desc')}
                                    <ul>
                                        <li>{t('spec_slam')}</li>
                                        <li>{t('spec_m')}</li>
                                    </ul>
                                    {t('bts_place')}
                                </p>
                            </div>
                            <a class="read-more" className="p-4">{t('download_r')}<span class="sr-only"></span>
                                <svg xmlns="http://www.w3.org/2000/svg" class="icon" viewBox="0 0 20 20" fill="currentColor">
                                    <path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd" />
                                </svg>
                            </a>
                        </article>
                    </div>
                    <Modal show={showBtsDownloads} centered onHide={handleClosesetShowBtsDownloads} animation={false}>
                        <Modal.Header closeButton className="pr-4">
                            <Modal.Title>Documents</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="p-2 documentpointer" onClick={() => downloadFileByLang(filePerLangReportCard, t)}>{t('report_card')}</div>
                            <div className="p-2 documentpointer" onClick={() => downloadFileByLang(filePerLangMockExam, t)}>{t('mock_exam')}</div>
                            <div className="p-2 documentpointer" onClick={() => downloadFileByLang(filePerLangSCFY, t)}>{t('enrollment_certificate1')}</div>
                            <div className="p-2 documentpointer" onClick={() => downloadFileByLang(filePerLangSCSY, t)}>{t('enrollment_certificate2')}</div>
                        </Modal.Body>
                    </Modal>

                    <div className="flex-auto cpointer" onClick={handleShowsetShowWhatNextDownloads}>
                        <article className="border rounded-lg overflow-hidden" style={{ height: "490px" }}>
                            <figure>
                                <img src={studying_abroad_image} alt="Placeholder" className="w-full h-48 object-cover" />
                            </figure>
                            <div className="p-4 sameHeigthDiv">
                                <h2 className="text-base font-semibold mb-2">{t('next')}</h2>
                                <p className="text-xs text-gray-700">
                                    {t('what_next')}
                                    <ul>
                                        <li>{t('english_zone')}</li>
                                        <li>{t('united_state_zone')}</li>
                                        <li>{t('canada_zone')}</li>
                                    </ul>
                                </p>
                            </div>
                            <a class="read-more" className="pl-6 pt-1.5 FIXEDPOSITION">{t('download_r')}<span class="sr-only"></span>
                                <svg xmlns="http://www.w3.org/2000/svg" class="icon" viewBox="0 0 20 20" fill="currentColor">
                                    <path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd" />
                                </svg>
                            </a>
                        </article>
                    </div>
                    <Modal show={showWhatNextDownloads} centered onHide={handleClosesetShowWhatNextDownloads} animation={false}>
                        <Modal.Header closeButton className="pr-4">
                            <Modal.Title>Documents</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="p-2 documentpointer">TOEFL iBT scores</div>
                            <div className="p-2 documentpointer" onClick={() => downloadFile(sat_score_proof, sat_score_file_name)}>SAT scores</div>
                        </Modal.Body>
                    </Modal>
                </div>
            </div>
        </div>
    );
}
 
export default SchoolCurriculum;