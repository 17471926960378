import "./Footer.css";
import { useTranslation } from 'react-i18next';
import { useState, useRef } from 'react';
import Overlay from 'react-bootstrap/Overlay';
import Tooltip from 'react-bootstrap/Tooltip';

function copyToClipboard(text) {
    const textarea = document.createElement('textarea');
    textarea.value = text;
    textarea.style.position = 'fixed';
    textarea.style.opacity = 0;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
}

function getNumberByLanguage(language) {
    const numbersByLanguage = {
      en: '00330619378929',
      fr: '0619378929',
      us: '011330619378929',
      ca: '011330619378929'
    };
  
    return numbersByLanguage[language] || 0;
}

const Footer = () => {
    const [showteams, setShowteams] = useState(false);
    const [showlinkedin, setShowlinkedin] = useState(false);
    const [showmail, setShowmail] = useState(false);
    const [showphone, setShowphone] = useState(false);
    const target_mail = useRef(null);
    const target_phone = useRef(null);
    const target_linkedin = useRef(null);
    const target_teams = useRef(null);
    const { t, i18n } = useTranslation();

    const handleClickToCopy = (textToCopy, setShowFunction) => {
      copyToClipboard(textToCopy);
      setShowFunction(true);
      setTimeout(() => {
        setShowFunction(false);
      }, 1200);
    };

    return (
        <div className="flex flex-col md:flex-row lg:max-w-7xl lg:mt-[200px] mt-[80px] mx-auto items-center text-justify w-full"  style={{ zIndex: -1 }}>
            <div className="text-sm lg:ml-7 pt-4 pr-4 pb-0 w-full mb-0 grid grid-cols-2 gap-4">
                <h1 className="pb-4 text-base sm:text-sm pl-4">© Aurélien PICHERY</h1>
                <div className="pl-15">
                    <h1 className="pb-4 text-lg sm:w-[110%] md:w-[100%] flex justify-end space-x-2">
                        <span ref={target_teams} onClick={() => handleClickToCopy('', setShowteams)}><i className="bi bi-microsoft-teams m-1 pl-1.5 pr-1.5 pt-1 pb-1 cursor-pointer border border-black rounded-[6px]"/></span>
                        <Overlay target={target_teams.current} show={showteams} placement="top">{(props) => (<Tooltip {...props}>Copied!</Tooltip>)}</Overlay>
                        <span ref={target_linkedin} onClick={() => handleClickToCopy('', setShowlinkedin)}><i className="bi bi-linkedin m-1 pl-1.5 pr-1.5 pt-1 pb-1 cursor-pointer border border-black rounded-[6px]"/></span>
                        <Overlay target={target_linkedin.current} show={showlinkedin} placement="top">{(props) => (<Tooltip {...props}>Copied!</Tooltip>)}</Overlay>
                        <span ref={target_mail} onClick={() => handleClickToCopy('aurelien.pichery@orange.fr', setShowmail)}><i className="bi bi-envelope m-1 pl-1.5 pr-1.5 pt-1 pb-1 cursor-pointer border border-black rounded-[6px]"/></span>
                        <Overlay target={target_mail.current} show={showmail} placement="top">{(props) => (<Tooltip {...props}>Copied!</Tooltip>)}</Overlay>
                        <span ref={target_phone} onClick={() => handleClickToCopy(getNumberByLanguage(i18n.language), setShowphone)}><i className="bi bi-telephone-fill m-1 pl-1.5 pr-1.5 pt-1 pb-1 cursor-pointer border border-black rounded-[6px]"/></span>
                        <Overlay target={target_phone.current} show={showphone} placement="top">{(props) => (<Tooltip {...props}>Copied!</Tooltip>)}</Overlay>
                    </h1>
                </div>
            </div>
        </div>
    );
}

export default Footer;