import React from 'react';
import './App.css'
import CustomNavbar from './Components/CustomNavbar/CustomNavbar';
import Description from './Components/Description/Description'
import Projects from './Components/Projects/Projects';
import SchoolCurriculum from './Components/SchoolCurriculum/SchoolCurriculum'
import WorkExperience from './Components/WorkExperience/WorkExperience'
import Footer from './Components/Footer/Footer'

// Known as the root component of the application, working at the very top of our application.
// All others component will so be nested inside of the app component. <hr className='lg:max-w-7xl mx-auto'/>
function App() {
  return (
    <div className="App" style={{ height: '2000px' }}>
      <CustomNavbar className='nvb'/>
      <div className='justify-center'>
        <Description id="AboutMe"/>
        <SchoolCurriculum id="SchoolCurriculum"/>
        <WorkExperience id="WorkExperience"/>
        <Projects id="Projects"/>
        <Footer/>
      </div>
    </div>
  );
}

export default App;
