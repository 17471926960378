import "./CustomNavbar.css";
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useTranslation } from 'react-i18next';
import en_flag from '../../imgs/en_flag.png';
import fr_flag from '../../imgs/fr_flag.png';
import us_flag from '../../imgs/us_flag.png';
import ca_flag from '../../imgs/ca_flag.png';

function getNumberByLanguage(language) {
  const numbersByLanguage = {
    en: '00330619378929',
    fr: '0619378929',
    us: '011330619378929',
    ca: '011330619378929'
  };

  return numbersByLanguage[language] || 0;
}

function getFlagPngNameByLanguage(language) {
  const numbersByLanguage = {
    en: en_flag,
    fr: fr_flag,
    us: us_flag,
    ca: ca_flag
  };

  return numbersByLanguage[language] || en_flag;
}


const CustomNavbar = () => {
    const { t, i18n } = useTranslation();
  
    const changeLanguage = (lng) => {
      i18n.changeLanguage(lng);
    };

    return (  
      <Navbar key='lg' expand='lg' className="nvb_pss mb-3 bg-white" style={{ position: 'fixed', width: '100%', top: '0', zIndex: 1 }}>
        <Container fluid>
          <Navbar.Brand>{t('name')}</Navbar.Brand>
          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-$lg`} />
          <Navbar.Offcanvas id={`offcanvasNavbar-expand-$lg`} aria-labelledby={`offcanvasNavbarLabel-expand-$lg`} placement="end">
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-$lg`}>{t('menu')}</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-end flex-grow-1 pe-3">
                <Nav.Link href="#AboutMe" style={{ color: 'black' }}>{t('about_me')}</Nav.Link>
                <Nav.Link href="#SchoolCurriculum" style={{ color: 'black' }}>{t('scholarship_curriculum')}</Nav.Link>
                <Nav.Link href="#action4" style={{ color: 'black' }}>{t('work_experiences')}</Nav.Link>
                <Nav.Link href="#action5" style={{ color: 'black' }}>{t('projects')}</Nav.Link>
                <Nav.Link href="#action6" style={{ color: 'black' }}>{t('skills')}</Nav.Link>
                <NavDropdown title={<div className="inline-flex items-center"><span href='#lng' style={{ textDecoration: 'none', color: 'black' }}>{t('contact')}</span></div>} id={`offcanvasNavbarDropdown-expand-$lg`} className='spec_nav_dropdown_mail custom-dropdown2'>
                  <NavDropdown.Item href="#action3" style={{ height: '28px' }}><p href="#mail" onClick={() => window.open('mailto:aurelien.pichery@gmail.com', '_blank')}><i className="bi bi-envelope"/>&nbsp;&nbsp;aurelien.pichery@gmail.com</p></NavDropdown.Item>
                  <NavDropdown.Item href="#action4" style={{ height: '28px' }}><p href="#phone" onClick={() => window.open('tel:' + getNumberByLanguage(i18n.language), '_blank')}><i className="bi bi-telephone-fill"/>&nbsp;&nbsp;{t('phone_number')}</p></NavDropdown.Item>
                  <NavDropdown.Item href="#action4" style={{ height: '28px' }}><p><i className="bi bi-linkedin"/>&nbsp;&nbsp;Linkedin</p></NavDropdown.Item>
                </NavDropdown>
                <NavDropdown title={<div className="inline-flex items-center">
                    <img src={getFlagPngNameByLanguage(i18n.language)} alt={t('zone')} className="inline-block"/>
                    <span href="#lng" style={{ textDecoration: 'none', color: 'black' }} className="ml-2 inline-block">{t('zone')}</span>
                  </div>} className="spec_nav_dropdown_langs custom-dropdown" id={`offcanvasNavbarDropdown-expand-$lg`}>
                  <NavDropdown.Item onClick={() => changeLanguage('en')} style={{ paddingLeft: '12px', paddingRight: '12px', color: 'black' }}>
                    <img src={en_flag} alt={t('english_zone')} style={{ display: 'inline-block', height: '24px', backgroundSize: 'cover' }}></img><a style={{ display: 'inline-block' }}>&nbsp;&nbsp;{t('english_zone')}</a>
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={() => changeLanguage('fr')} style={{ paddingLeft: '12px', paddingRight: '12px', color: 'black' }}>
                    <img src={fr_flag} alt={t('french_zone')} style={{ display: 'inline-block', height: '24px', backgroundSize: 'cover' }}></img><a style={{ display: 'inline-block' }}>&nbsp;&nbsp;{t('french_zone')}</a>
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={() => changeLanguage('us')} style={{ paddingLeft: '12px', paddingRight: '12px', color: 'black' }}>
                    <img src={us_flag} alt={t('united_state_zone')} style={{ display: 'inline-block', height: '24px', backgroundSize: 'cover' }}></img><a style={{ display: 'inline-block' }}>&nbsp;&nbsp;{t('united_state_zone')}</a>
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={() => changeLanguage('ca')} style={{ paddingLeft: '12px', paddingRight: '12px', color: 'black' }}>
                    <img src={ca_flag} alt={t('canada_zone')} style={{ display: 'inline-block', height: '24px', backgroundSize: 'cover' }}></img><a style={{ display: 'inline-block' }}>&nbsp;&nbsp;{t('canada_zone')}</a>
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
              <Form className="d-flex">
                <Form.Control type="search" placeholder={t('search')} className="me-2" aria-label="Search" />
                <Button variant="outline-success">{t('search')}</Button>
              </Form>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    );
}
 
export default CustomNavbar;