import "./Description.css";
import { useTranslation } from 'react-i18next';
import aviation_image from '../../imgs/aviation.png';
import ski_image from '../../imgs/ski.png';
import aviation_proof from '../../downloads/aurelien_pichery_aviation_certificate_bia.pdf';
import ski_proof from '../../downloads/aurelien_pichery_french_ski_national_ranking.pdf';

function downloadFile(url, filename) {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

const Description = () => {
    const { t } = useTranslation();

    return (  
        <div className="flex flex-col md:flex-row lg:max-w-7xl lg:mt-[170px] mt-[60px] mx-auto text-justify w-full">
            <div className="flex flex-col lg:text-sm text-xs lg:ml-7 p-4 w-full lg:max-w-2xl mt-4">
                <h1 className="pb-4 text-lg lg:text-3xl">{t('main_title')}</h1>{t('description')}
                <div className="grid grid-cols-2 mt-auto mb-2 pt-4 lg:pt-0 text-sm lg:text-xl">
                    <div onClick={() => downloadFile(aviation_proof, 'aurelien_pichery_aviation_certificate_bia.pdf')} className="flex-auto lg:h-[140px] lg:w-[230px] md:h-[115px] md:w-[200px] h-[100px] w-[150px]">
                        <figure className="transitionDescHobbies lg:h-[140px] lg:w-[230px] md:h-[115px] md:w-[200px] h-[100px] w-[150px] relative">
                            <p className="pl-3 pb-2 text-white font-bold preTitleStyle absolute bottom-0 m-0">{t('aviation_certificate')}</p>
                            <img className="object-cover lg:h-[140px] lg:w-[230px] md:h-[115px] md:w-[200px] h-[100px] w-[150px]" src={aviation_image} alt="Aviation Certificate"/>
                            <figcaption className="text-white d-flex justify-content-center align-items-center"><i className="text-white bi bi-cloud-download-fill fs-2"/></figcaption>
                        </figure>
                    </div>
                    <div onClick={() => downloadFile(ski_proof, 'aurelien_pichery_french_ski_national_ranking.pdf')} className="flex-auto lg:h-[140px] lg:w-[230px] md:h-[115px] md:w-[200px] h-[100px] w-[150px] justify-self-end">
                        <figure className="transitionDescHobbies lg:h-[140px] lg:w-[230px] md:h-[115px] md:w-[200px] h-[100px] w-[150px] relative">
                            <p className="pl-3 pb-2 text-white font-bold preTitleStyle absolute bottom-0 m-0">{t('ski_prize_list')}</p>
                            <img className="object-cover lg:h-[140px] lg:w-[230px] md:h-[115px] md:w-[200px] h-[100px] w-[150px]" src={ski_image} alt="Ski Prize List"/>
                            <figcaption className="text-white d-flex justify-content-center align-items-center"><i className="text-white bi bi-cloud-download-fill fs-2"/></figcaption>
                        </figure>
                    </div>
                </div>
            </div>
            <div className="p-4 w-full flex justify-center md:w-auto md:ml-auto items-center">
                <img className="sm:max-w-[230px] sm:w-[230px] md:max-w-[230px] md:w-[230px] lg:max-w-[400px] lg:w-[400px] sm:max-h-[230px] sm:h-[230px] md:max-h-[230px] md:h-[230px] lg:max-h-[400px] lg:h-[400px] rounded-[10px]" src="https://via.placeholder.com/600" alt="Me" />
            </div>
        </div>
    );
}
 
export default Description;
