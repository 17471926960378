import "./WorkExperience.css";
import { useTranslation } from 'react-i18next';
import atos_building_img from '../../imgs/atos_building.png';
import atos_logo_img from '../../imgs/atos_logo.png'
import doxio_building_img from '../../imgs/doxio_building.png';
import doxio_logo_img from '../../imgs/doxio_logo.png';
import React, { useEffect, useRef, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Stack from 'react-bootstrap/Stack';

/**
 * downloadFileByLang - Made to download files when there is a matter of language
 * @param {const} filePerLangArray - Array of array of format [[importedURL, fileName, 'us'], [importedURL, fileName, 'fr'], ...].
 * @param {const} langState - State (t) of current lang set.
 */
function downloadFileByLang(filePerLangArray, langState) {
    let fileData = [];

    filePerLangArray.forEach(subArray => {
        if (subArray[2] === langState('current_lang')){
            fileData = [subArray[0], subArray[1]];
        }
    });

    const link = document.createElement('a');
    link.href = fileData[0];
    link.setAttribute('download', fileData[1]);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

const WorkExperience = () => {
    const [showDoxioDownloads, setShowDoxioDownloads] = useState(false);
    const [showAtosDownloads, setShowAtosDownloads] = useState(false);
    
    const handleShowsetShowDoxioDownloads = () => { setShowDoxioDownloads(true) };
    const handleClosesetShowDoxioDownloads = () => { setShowDoxioDownloads(false) };
    const handleShowsetShowAtosDownloads = () => { setShowAtosDownloads(true); };
    const handleClosesetShowAtosDownloads = () => { setShowAtosDownloads(false); };

    const { t } = useTranslation();

    return (  
        <div className="flex flex-col md:flex-row lg:max-w-7xl lg:mt-[200px] mt-[80px] mx-auto items-center text-justify w-full"  style={{ zIndex: -1 }}>
            <div className="text-sm lg:ml-7 p-4 w-full mb-0">
                <h1 className="pb-4 text-lg lg:text-3xl">{t('work_experiences')}</h1>
                <ul class="cards grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">
                    <li class="cardLi cursor-pointer" onClick={handleShowsetShowDoxioDownloads}>
                        <a class="card">
                            <img src={doxio_building_img} class="card__image" alt="" />
                            <div class="card__overlay">
                                <div class="card__header text-xs">
                                    <svg class="card__arc" xmlns="http://www.w3.org/2000/svg"><path /></svg>                     
                                    <img class="card__thumb" src={doxio_logo_img} alt="" />
                                    <div class="card__header-text">
                                        <h3 class="card__title">{t('doxio_title')}</h3>            
                                        <span class="card__status">{t('doxio_date')}  |  <i className="bi bi-geo-alt-fill"/> {t('doxio_location')}</span>
                                    </div>
                                </div>
                                <div className="card__description_1">
                                    <div className="textDesc text-xs grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-0">
                                        <div className="displayLeft">
                                            <p>{t('doxio_desc')}</p>
                                        </div>
                                        <div className="displayRight lg:pl-8">
                                            <p><strong>{t('mentioning')}</strong>
                                                <ul>
                                                    <li>{t('doxio_men1')}</li>
                                                    <li>{t('doxio_men2')}</li>
                                                    <li>{t('doxio_men3')}</li>
                                                    <li>{t('doxio_men4')}</li>
                                                    <li></li>
                                                </ul>
                                            </p>
                                        </div>
                                        <div className="botDesc mb-1">
                                            <Stack direction="horizontal" gap={2}>
                                                <span>{t('languages')}</span>
                                                <span className="cs badge">C#</span>
                                                <span className="htmlaspx badge">HTML.aspx</span>
                                            </Stack>
                                        </div>
                                    </div>
                                    <a className="mt-3 ml-1 readmore">{t('download_r')}<span class="sr-only"></span>
                                        <svg xmlns="http://www.w3.org/2000/svg" class="icon" viewBox="0 0 20 20" fill="currentColor">
                                            <path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd" />
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </a>      
                    </li>
                    <Modal show={showDoxioDownloads} centered onHide={handleClosesetShowDoxioDownloads} animation={false}>
                        <Modal.Header closeButton className="pr-4">
                            <Modal.Title>Documents</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="p-2 documentpointer" onClick={() => downloadFileByLang('temp', 'temp')}>{t('internship_report')}</div>
                            <div className="p-2 documentpointer" onClick={() => downloadFileByLang('temp', 'temp')}>{t('internship_certificate')}</div>
                        </Modal.Body>
                    </Modal>

                    <li class="cardLi cursor-pointer" onClick={handleShowsetShowAtosDownloads}>
                        <a class="card">
                            <img src={atos_building_img} class="card__image" alt="" />
                            <div class="card__overlay">        
                                <div class="card__header header_1_var_class text-xs">
                                    <svg class="card__arc" xmlns="http://www.w3.org/2000/svg"><path /></svg>                 
                                    <img class="card__thumb" src={atos_logo_img} alt="" />
                                    <div class="card__header-text">
                                        <h3 class="card__title">{t('atos_title')}</h3>
                                        <span class="card__status">{t('atos_date')}  |  <i className="bi bi-geo-alt-fill"/>{t('atos_location')}</span>
                                    </div>
                                </div>
                                <div className="card__description_2">
                                    <div className="textDesc text-xs grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-0">
                                        <div className="displayLeft">
                                            <p>{t('atos_desc')}</p>
                                            <div className="botDesc mb-1">
                                                <Stack direction="horizontal" gap={2}>
                                                    <span>{t('languages')}</span>
                                                    <span className="sql badge">SQL</span>
                                                </Stack>
                                            </div>
                                        </div>
                                        <div className="displayRight lg:pl-8">
                                            <p><strong>{t('mentioning')}</strong>
                                                <ul>
                                                    <li>{t('atos_men1')}</li>
                                                    <li>{t('atos_men2')}</li>
                                                    <li>{t('atos_men3')}</li>
                                                    <li>{t('atos_men4')}</li>
                                                    <li>{t('atos_men5')}</li>
                                                </ul>
                                            </p>
                                        </div>
                                    </div>
                                    <a className="mt-3 ml-1 readmore">{t('download_r')}<span class="sr-only"></span>
                                        <svg xmlns="http://www.w3.org/2000/svg" class="icon" viewBox="0 0 20 20" fill="currentColor">
                                            <path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd" />
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </a>
                    </li>
                    <Modal show={showAtosDownloads} centered onHide={handleClosesetShowAtosDownloads} animation={false}>
                        <Modal.Header closeButton className="pr-4">
                            <Modal.Title>Documents</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="p-2 documentpointer" onClick={() => downloadFileByLang('temp', 'temp')}>{t('internship_report')}</div>
                            <div className="p-2 documentpointer" onClick={() => downloadFileByLang('temp', 'temp')}>{t('internship_certificate')}</div>
                        </Modal.Body>
                    </Modal>
                </ul>
            </div>
        </div>
    );
}
 
export default WorkExperience;